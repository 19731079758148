import React from "react"
import Layout from "../../components/layout/Layout.js"
import { BlogRow, Row } from "../../components/row/Row.js"
import {
  Title,
  SubHeading,
  BlogTitle,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/co-marketing-deck.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { Section } from "../../components/section/Section.js"
import { ButtonContainer, Contact } from "../../components/button/Button.js"



function CoMarketingDeck({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Co-Marketing Deck Template" 
       description="This 50-slide template deck is meant to help you structure your co-marketing approach, while managing your partners' expectations."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/co-marketing-deck/">Co-marketing deck - Template</a>
            </li>
          </Breadcrumb>
        </Row>
          <Row isCentered>
          <Column50>
             <BlogTitle title="Co-Marketing Deck" />
            <SubHeading subheading="This 50-slide template deck is meant to help you structure your co-marketing approach, while managing your partners' expectations." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/co-marketing-template" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Co-Marketing Deck" />
          </Column50>
        </Row>
      </AboveFold>


      <Section isCentered>   
        <BlogRow isCentered>
          <TextMedium textMedium="We’ve used this template when creating co-marketing plans for B2B companies of all sizes, from growing startups 
          and scale-ups to large enterprises."/> 
          <TextMedium textMedium="The deck includes three sections: the first one gives the overview of your company and approach to marketing in general. Part two focuses on co-marketing from a high-level perspective, and the last part of the deck gives practical examples of the different deliverables you can use in your co-marketing program." />          <GumroadForm 
                formName="Co-Marketing Deck"
                redirectPage="https://socontent.gumroad.com/l/co-marketing-template"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/co-marketing-template"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default CoMarketingDeck